import { z } from 'zod';

const aboutUsLocalizationSchema = z.object({
  copyDetail: z.string(),
  aboutUsVideoUrl: z.string(),
});

export type AboutUsLocalization = z.infer<typeof aboutUsLocalizationSchema>;

const communityLocalizationSchema = z
  .object({
    copyDetail: z.string().optional(),
    communityImageUrl: z.string().optional(),
    communityImageUrls: z.array(z.string()).optional(),
    communityVideoUrl: z.string().optional(),
    forms: z.array(z.string()).optional(),
    formsEmail: z
      .string()
      .email({ message: 'Please enter a valid email address.' })
      .optional()
      .or(z.literal('')),
  })
  .refine(
    data =>
      (data.forms?.length && data.formsEmail?.length) || !data.forms?.length,
    {
      message: 'Please enter a valid email address.',
      path: ['formsEmail'],
    },
  );

export type CommunityLocalization = z.infer<typeof communityLocalizationSchema>;

const localizationSchema = z.object({
  copyDetail: z.string().optional(),
  heading: z.string().optional(),
  copyBullets: z.array(z.any()),
  careerImageUrls: z.array(z.any()).optional(),
  careerVideoUrl: z.string().optional(),
});

export const localizationResponseSchema = z.object({
  careers: localizationSchema,
  aboutUs: aboutUsLocalizationSchema,
  community: communityLocalizationSchema,
});

type Localization = z.infer<typeof localizationResponseSchema>;

export default Localization;
